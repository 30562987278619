import styled from 'styled-components';
import { mediaInv } from '../../../ui/common';

export const TextImgCont = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  ${mediaInv.tablet`
     flex-wrap: wrap;
     margin-bottom: 21px;
  `}
`;

export const Texts = styled.div`
  box-sizing: border-box;
  font-family: 'HelveticaNeueCyr';
  color: #323232;
  width: 48%;
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 16px;
    margin-top: 0;
    ${mediaInv.tablet`
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 21px;
  `}
  }
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 23px;
    margin-top: 0;
    ${mediaInv.laptop`
    margin-bottom: 21px;
  `}
  }
  & p:last-child {
    margin-bottom: 0;
  }
  ${mediaInv.tablet`
     width: 100%;
     & .TextImg__text-desk {
      display: none;
     }
  `}
`;

export const ImgCont = styled.div`
  box-sizing: border-box;
  width: 48%;
  & .gatsby-image-wrapper {
    ${mediaInv.laptop`
     height: 354px;
     border-radius: 24px;
     & img {
      border-radius: 24px;
     }
  `}
    ${mediaInv.tablet`
    height: 350px;
    & img {
      border-radius: 24px;
     }
  `}
  }
  ${mediaInv.medium`
    & .bottomComponent {
      height: 300px;
    }
  `}
  ${mediaInv.tablet`
    width: 100%;
  `}
`;

export const TextImgMob = styled.div`
  box-sizing: border-box;
  display: none;
  ${mediaInv.tablet`
     display: block;
     margin-bottom: 100px;
     font-family: 'HelveticaNeueCyr';
  `}
`;
