import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntersectionObserver from '@react-hook/intersection-observer';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import chill1 from './icons/chill1.svg';
import chill2 from './icons/chill2.svg';
import chill3 from './icons/chill3.svg';
import { ChillCont, ChillItems } from './styled';
import chillD from './video/chilld.mp4';
import chillM from './video/chillm.mp4';

function ChillVideo({ titles, texts }) {
  const chills = [chill1, chill2, chill3];
  const size = useWindowSize();
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting) {
    lockRef.current = true;
  }
  const currentLocaleClass = useSelector(
    (state) => `ChillItems__middle lang-special ${state.locale.picked}`
  );
  return (
    <ContBig>
      <ChillCont ref={containerRef}>
        {lockRef.current && (
          <video
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            webkit-playsInline="webkit-playsInline"
            playsInline
          >
            <source src={size.width > 599 ? chillD : chillM} type="video/mp4" />
          </video>
        )}
      </ChillCont>
      <ChillItems>
        {titles.map((title, i) => (
          <div
            className="ChillItems__item"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <div className="ChillItems__top">
              <img src={chills[i]} alt="" />
            </div>
            <div className={currentLocaleClass}>{title}</div>
            <div className="ChillItems__bottom">{texts[i]}</div>
          </div>
        ))}
      </ChillItems>
    </ContBig>
  );
}
export default ChillVideo;

ChillVideo.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.any),
  titles: PropTypes.arrayOf(PropTypes.any),
};

ChillVideo.defaultProps = {
  texts: [],
  titles: [],
};
