import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import { BornFromCont, BornFromPics } from './styled';

function BornFrom({ title, images, text }) {
  const size = useWindowSize();
  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );
  return (
    <ContBig>
      <BornFromCont>
        <h2 className={currentLocaleClass}>{title}</h2>
        <p>{text}</p>
        <BornFromPics>
          <GatsbyImage image={images[0]} loading="eager" />
          <GatsbyImage image={images[1]} loading="eager" />
          {size.width > 767 ? (
            <GatsbyImage
              image={size.width >= 1199 ? images[2] : images[3]}
              loading="eager"
            />
          ) : (
            <GatsbyImage image={images[4]} loading="eager" />
          )}
        </BornFromPics>
      </BornFromCont>
    </ContBig>
  );
}
export default BornFrom;

BornFrom.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.arrayOf(PropTypes.any),
};

BornFrom.defaultProps = {
  images: [],
  title: null,
  text: null,
};
