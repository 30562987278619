import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const EffectiveCont = styled.div`
  box-sizing: border-box;
  color: ${colors.color28};
  margin-bottom: 100px;
  text-align: center;
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    max-width: 616px;
    margin: 0 auto 0 auto;
    ${mediaInv.tablet`
    font-size: 28px;
    line-height: 31px;
`}
  }
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & p {
    font-family: 'HelveticaNeueCyr';
    font-size: 16px;
    line-height: 20px;
    margin: 16px auto 40px auto;
    max-width: 616px;
    ${mediaInv.tablet`
    margin-bottom: 15px;
`}
  }
  & .activeClicker .Clicker__action {
    justify-content: flex-end;
    background: #3578ff;
  }
  & .activeCelsius {
    & .EffectPics__f {
      display: none;
    }
    & .EffectPics__c {
      display: block;
    }
  }
`;

export const EffectPics = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  ${mediaInv.laptop`

`}
  & .gatsby-image-wrapper {
    width: 48.1%;
    ${mediaInv.medium`
   width: 100%;
`}
  }
  ${mediaInv.medium`
   & .EffectPics__c {
    display: none;
   }
`}
`;

export const Clicker = styled.div`
  box-sizing: border-box;
  display: none;
  font-family: 'HelveticaNeueCyr';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  justify-content: space-between;
  align-items: center;
  background: #f0f5ff;
  border-radius: 16px;
  width: 106px;
  height: 38px;
  padding: 0 10px;
  margin: 0 auto 15px auto;
  & .Clicker__action {
    cursor: pointer;
    box-sizing: border-box;
    background: #f3f3f3;
    border-radius: 12px;
    width: 40px;
    height: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 3px;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    & .Clicker__rider {
      box-sizing: border-box;
      background: ${colors.color15};
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
        0px 4px 6px -1px rgba(0, 0, 0, 0.08);
      border-radius: 17px;
      width: 18px;
      height: 12px;
    }
  }
  ${mediaInv.medium`
   display: flex;
`}
`;
