import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowSize from '../../../utils/useWindowSize';
import { ContBig } from '../../../ui/common';
import { Texts, TextImgCont, ImgCont, TextImgMob } from './styled';

function TextImg({ image, imageTablet, imageMob, title, texts, position }) {
  const size = useWindowSize();
  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );
  return (
    <ContBig>
      <TextImgCont>
        <Texts>
          <h2 className={currentLocaleClass}>{title}</h2>
          {texts.map((text, i) => (
            <p
              className="TextImg__text-desk"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              {text}
            </p>
          ))}
        </Texts>
        <ImgCont>
          {size.width > 767 ? (
            <GatsbyImage
              className={position === 'upper' ? null : 'bottomComponent'}
              image={size.width >= 1199 ? image : imageTablet}
              loading="eager"
            />
          ) : (
            <GatsbyImage
              className={position === 'upper' ? null : 'bottomComponent'}
              image={imageMob}
              loading="eager"
            />
          )}
        </ImgCont>
      </TextImgCont>
      <TextImgMob>
        {texts.map((text, i) => (
          <p
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {text}
          </p>
        ))}
      </TextImgMob>
    </ContBig>
  );
}
export default TextImg;

TextImg.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  imageTablet: PropTypes.objectOf(PropTypes.any),
  imageMob: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  texts: PropTypes.arrayOf(PropTypes.any),
  position: PropTypes.node,
};

TextImg.defaultProps = {
  image: {},
  imageTablet: {},
  imageMob: {},
  title: null,
  texts: [],
  position: null,
};
