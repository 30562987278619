import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useIntersectionObserver from '@react-hook/intersection-observer';
import useWindowSize from '../../../utils/useWindowSize';
import useVideoAutoPlayback from '../../../hooks/useVideoAutoPlayback';
import { ContBig } from '../../../ui/common';
import { VideosCont, BigVideo, BottomVideos, BottomInfo } from './styled';
import VideoDesk from './video/water-desk.gif';
import VideoMob from './video/water-mob.gif';
import expDesk from './video/expDesk.mp4';
import expTablet from './video/expTablet.mp4';
import expMob from './video/expMob.mp4';

function TechVideos({
  title,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
}) {
  const size = useWindowSize();

  const containerRefBig = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRefBig);
  if (isIntersecting) {
    lockRef.current = true;
  }

  const [containerRef, videoRef] = useVideoAutoPlayback({
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  return (
    <ContBig>
      <VideosCont>
        <h2 className={currentLocaleClass}>{title}</h2>
        <p>{text1}</p>
        <BigVideo ref={containerRefBig}>
          {lockRef.current && (
            <div className="BigVideo__gif">
              <img src={size.width > 767 ? VideoDesk : VideoMob} alt="" />
            </div>
          )}
          <div className="BigVideo__panel">{text2}</div>
        </BigVideo>
        <p className="VideosCont__text-between">{text3}</p>
        <BottomVideos ref={containerRef}>
          <div className="BottomVideos__videoCont">
            {size.width > 767 ? (
              <video
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                webkit-playsInline="webkit-playsInline"
                playsInline
                ref={videoRef}
              >
                <source
                  src={size.width >= 1199 ? expDesk : expTablet}
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                webkit-playsInline="webkit-playsInline"
                playsInline
                ref={videoRef}
              >
                <source src={expMob} type="video/mp4" />
              </video>
            )}
          </div>
        </BottomVideos>
        <BottomInfo>
          <span>{text4} </span>
          <span className="BottomInfo__bold">{text5} </span>
          <span>{text6} </span>
          <span className="BottomInfo__bold">{text7}</span>
        </BottomInfo>
      </VideosCont>
    </ContBig>
  );
}
export default TechVideos;

TechVideos.propTypes = {
  title: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  text4: PropTypes.node,
  text5: PropTypes.node,
  text6: PropTypes.node,
  text7: PropTypes.node,
};

TechVideos.defaultProps = {
  title: null,
  text1: null,
  text2: null,
  text3: null,
  text4: null,
  text5: null,
  text6: null,
  text7: null,
};
