import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const ScienceCont = styled.div`
  margin-top: 40px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 100px;
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & .gatsby-image-wrapper {
    ${mediaInv.laptop`
     height: 274px;
     border-radius: 24px;
     & img {
      border-radius: 24px;
     }
  `}
    ${mediaInv.tablet`
    min-height: 474px;
  `}
  }
  & .gatsby-image-wrapper.blockheight.ru {
    ${mediaInv.tablet`
    min-height: 530px;
  `}
  }
`;

export const ScienceAbout = styled.div`
  background: rgba(53, 120, 255, 0.3);
  backdrop-filter: blur(27px);
  border-radius: 16px;
  padding: 18px 32px;
  font-family: 'HelveticaNeueCyr';
  width: 556px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  box-sizing: border-box;
  color: ${colors.color15};
  ${mediaInv.laptop`
    width: calc(100% - 30px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px 32px;
  `}
  ${mediaInv.tablet`
    width: calc(100% - 24px);
    height: calc(100% - 24px);
  `}
   @media (max-width: 365px) {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    padding: 16px 10px;
  }
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin: 0 0 16px 0;
    ${mediaInv.tablet`
    font-size: 28px;
    line-height: 31px;
`}
  }
  & p {
    margin-bottom: 22px;
    margin-top: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    ${mediaInv.laptop`
    margin-bottom: 19px;
 `}
  }
  & p:last-child {
    margin-bottom: 0;
  }
`;
