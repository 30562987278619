import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const VideosCont = styled.div`
  box-sizing: border-box;
  background: #f0f5ff;
  border-radius: 24px;
  padding: 55px 110px;
  font-family: 'HelveticaNeueCyr';
  color: #3e3e3e;
  text-align: center;
  margin-bottom: 100px;
  & video::-webkit-media-controls-panel {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    opacity: 0 !important;
  }

  & video::-webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    opacity: 0 !important;
  }

  & video::-webkit-media-controls-start-playback-button {
    display: none !important;
    opacity: 0 !important;
  }
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin: 0 0 15px 0;
  }
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }
  & .VideosCont__text-between {
    max-width: 616px;
    margin-left: auto;
    margin-right: auto;
  }
  ${mediaInv.laptop`
    padding: 55px 25px;
  `}
  ${mediaInv.tablet`
    padding: 55px 15px;
    & h2 {
      font-size: 28px;
      line-height: 31px;
    }
  `}
`;

export const BigVideo = styled.div`
  border-radius: 24px;
  position: relative;
  height: 300px;
  overflow: hidden;
  margin: 40px 0;
  position: relative;
  z-index: 10;
  & .BigVideo__gif img {
    max-width: 100%;
    min-width: 100%;
    border-radius: 24px;
  }
  & .BigVideo__panel {
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    width: 500px;
    box-sizing: border-box;
    color: ${colors.color15};
    background: rgba(53, 120, 255, 0.3);
    backdrop-filter: blur(27px);
    border-radius: 12px;
    padding: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${mediaInv.tablet`
      width: 246px;
   `}
  }
`;

export const BottomVideos = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: space-between;
  & .BottomVideos__videoCont {
    width: 100%;
    border-radius: 24px;
    & video {
      height: auto;
      width: 100%;
      border-radius: 24px;
    }
  }
  ${mediaInv.medium`
      flex-wrap: wrap;
      margin: 40px 0 20px 0;
      & .BottomVideos__videoCont {
        width: 100%;
        margin-bottom: 15px;
        position: relative;
        z-index: 10;
      }
   `}
`;

export const BottomInfo = styled.div`
  max-width: 616px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 2px solid ${colors.color27};
  border-top: 2px solid ${colors.color27};
  padding: 16px 0;
  font-size: 20px;
  line-height: 24px;
  & .BottomInfo__bold {
    font-weight: bold;
  }
`;
