import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, StaticQuery } from 'gatsby';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import Breadcrumbsnew from '../components/Breadcrumbs';
import Science from '../components/Technology/Science';
import TextImg from '../components/Technology/TextImg';
import TechVideos from '../components/Technology/TechVideos';
import BornFrom from '../components/Technology/BornFrom';
import ChillVideo from '../components/Technology/ChillVideo';
import HowEffect from '../components/Technology/HowEffect';
import DiffLanding from '../components/DiffLanding';

export const promo = graphql`
  {
    section1: file(relativePath: { eq: "tech-page/science.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    section2: file(relativePath: { eq: "tech-page/ocean.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    fahrenheit: file(relativePath: { eq: "tech-page/fahrenheit.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    celsius: file(relativePath: { eq: "tech-page/celsius.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    born1: file(relativePath: { eq: "tech-page/born1.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    born2: file(relativePath: { eq: "tech-page/born2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    born3: file(relativePath: { eq: "tech-page/born3.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    born4: file(relativePath: { eq: "tech-page/born3-tablet.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    born5: file(relativePath: { eq: "tech-page/born3-mob.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    bornblock: file(relativePath: { eq: "tech-page/bornblock.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    bornblockTablet: file(
      relativePath: { eq: "tech-page/bornblock-tablet.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    bornblockMob: file(relativePath: { eq: "tech-page/bornblock-mob.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
  }
`;

export default function Technology() {
  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <>
          <NavBar />

          <Breadcrumbsnew
            text1={<FormattedMessage id="techPage.breadcrumb.text1" />}
            text2={<FormattedMessage id="techPage.breadcrumb.text2" />}
          />

          <Science
            image={data.section1.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="techPage.section1.title" />}
            text1={<FormattedMessage id="techPage.section1.text1" />}
            text2={<FormattedMessage id="techPage.section1.text2" />}
            text3={<FormattedMessage id="techPage.section1.text3" />}
          />

          <TextImg
            image={data.section2.childImageSharp.gatsbyImageData}
            imageTablet={data.section2.childImageSharp.gatsbyImageData}
            imageMob={data.section2.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="techPage.section2.title" />}
            texts={[
              <FormattedMessage id="techPage.section2.text1" />,
              <FormattedMessage id="techPage.section2.text2" />,
              <FormattedMessage id="techPage.section2.text3" />,
            ]}
            position="upper"
          />

          <TechVideos
            title={<FormattedMessage id="techPage.section3.title" />}
            text1={<FormattedMessage id="techPage.section3.text1" />}
            text2={<FormattedMessage id="techPage.section3.text2" />}
            text3={<FormattedMessage id="techPage.section3.text3" />}
            text4={<FormattedMessage id="techPage.section3.text4" />}
            text5={<FormattedMessage id="techPage.section3.text5" />}
            text6={<FormattedMessage id="techPage.section3.text6" />}
            text7={<FormattedMessage id="techPage.section3.text7" />}
          />

          <HowEffect
            image={data.fahrenheit.childImageSharp.gatsbyImageData}
            image2={data.celsius.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="techPage.section4.title" />}
            text={<FormattedMessage id="techPage.section4.text" />}
          />

          <ChillVideo
            titles={[
              <FormattedMessage id="techPage.section5.title1" />,
              <FormattedMessage id="techPage.section5.title2" />,
              <FormattedMessage id="techPage.section5.title3" />,
            ]}
            texts={[
              <FormattedMessage id="techPage.section5.text1" />,
              <FormattedMessage id="techPage.section5.text2" />,
              <FormattedMessage id="techPage.section5.text3" />,
            ]}
          />

          <BornFrom
            title={<FormattedMessage id="techPage.section6.title" />}
            images={[
              data.born1.childImageSharp.gatsbyImageData,
              data.born2.childImageSharp.gatsbyImageData,
              data.born3.childImageSharp.gatsbyImageData,
              data.born4.childImageSharp.gatsbyImageData,
              data.born5.childImageSharp.gatsbyImageData,
            ]}
            text={<FormattedMessage id="techPage.section6.text" />}
          />

          <TextImg
            image={data.bornblock.childImageSharp.gatsbyImageData}
            imageTablet={data.bornblockTablet.childImageSharp.gatsbyImageData}
            imageMob={data.bornblockMob.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="techPage.section7.title" />}
            texts={[<FormattedMessage id="techPage.section7.text1" />]}
            position="bottom"
          />

          <DiffLanding
            head={<FormattedMessage id="about_us.difference.head" />}
            titles={[
              <FormattedMessage id="about_us.difference.item1.title" />,
              <FormattedMessage id="about_us.difference.item2.title" />,
              <FormattedMessage id="about_us.difference.item3.title" />,
            ]}
            texts={[
              <FormattedMessage id="about_us.difference.item1.text" />,
              <FormattedMessage id="about_us.difference.item2.text" />,
              <FormattedMessage id="about_us.difference.item3.text" />,
            ]}
            links={[
              'https://evapolar.com/en/shop/',
              'https://evapolar.com/blog',
              'https://support.evapolar.com/hc/en-us/requests/new',
            ]}
          />

          <Footer />
        </>
      )}
    />
  );
}
