import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig } from '../../../ui/common';
import { EffectiveCont, EffectPics, Clicker } from './styled';

function HowEffect({ image, image2, title, text }) {
  const [active, setActive] = useState(null);

  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );

  return (
    <ContBig>
      <EffectiveCont>
        <h2 className={currentLocaleClass}>{title}</h2>
        <p>{text}</p>
        <Clicker
          onClick={() => setActive(!active)}
          className={active ? 'activeClicker' : null}
        >
          <div className="Clicker__title">°F</div>
          <div className="Clicker__action">
            <span className="Clicker__rider" />
          </div>
          <div className="Clicker__title">°C</div>
        </Clicker>
        <EffectPics className={active ? 'activeCelsius' : null}>
          <GatsbyImage
            className="EffectPics__f"
            image={image}
            loading="eager"
          />
          <GatsbyImage
            className="EffectPics__c"
            image={image2}
            loading="eager"
          />
        </EffectPics>
      </EffectiveCont>
    </ContBig>
  );
}
export default HowEffect;

HowEffect.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  image2: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text: PropTypes.node,
};

HowEffect.defaultProps = {
  image: {},
  image2: {},
  title: null,
  text: null,
};
