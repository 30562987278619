import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const ChillCont = styled.div`
  box-sizing: border-box;
  height: 550px;
  overflow: hidden;
  border-radius: 24px;
  position: relative;
  z-index: 10;
  & video {
    transform: scale(1.35);
  }
  & video::-webkit-media-controls-panel {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    opacity: 0 !important;
  }

  & video::-webkit-media-controls-play-button {
    display: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    opacity: 0 !important;
  }

  & video::-webkit-media-controls-start-playback-button {
    display: none !important;
    opacity: 0 !important;
  }
  ${mediaInv.laptop`
    & video {
      transform: scale(1.1);
      border-radius: 24px;
    }
  `}
  ${mediaInv.medium`
    & video {
      transform: scale(1.7);
    }
  `}
  ${mediaInv.tablet`
    & video {
      transform: scale(1.8);
    }
  `}
  @media (max-width: 500px) {
    & video {
      transform: scale(1.5);
    }
  }
`;

export const ChillItems = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: ${colors.color28};
  margin-top: 22px;
  margin-bottom: 100px;
  & .ChillItems__item {
    box-sizing: border-box;
    background: #f0f5ff;
    border-radius: 24px;
    padding: 32px;
    text-align: center;
    width: 32%;
    & .ChillItems__middle {
      font-family: 'Craftwork Grotesk';
      font-weight: 400;
      font-size: 28px;
      line-height: 31px;
      margin: 24px 0 15px 0;
      ${mediaInv.tablet`
      word-wrap: break-word;
  `}
    }
    & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
      font-family: 'Neue Machina';
      font-weight: 300;
    }
    & .ChillItems__bottom {
      font-family: 'HelveticaNeueCyr';
      font-size: 16px;
      line-height: 20px;
    }
    & .ChillItems__top img {
      box-shadow: 0px 16px 32px rgba(7, 18, 51, 0.2);
      border-radius: 50%;
    }
  }
  ${mediaInv.laptop`
     flex-wrap: wrap;
     & .ChillItems__item {
      width: 48%;
      margin-bottom: 24px;
     }
     & .ChillItems__item:last-child {
      width: 100%;
      margin-bottom: 0;
     }
  `}
  ${mediaInv.tablet`
     & .ChillItems__item {
      width: 100%;
     }
  `}
`;
