import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ContBig } from '../../../ui/common';
import { ScienceCont, ScienceAbout } from './styled';

function Science({ image, title, text1, text2, text3 }) {
  const currentLocaleClass = useSelector(
    (state) => `lang-special ${state.locale.picked}`
  );
  const ruBlockClass = useSelector(
    (state) => `blockheight ${state.locale.picked}`
  );
  return (
    <ContBig>
      <ScienceCont>
        <GatsbyImage className={ruBlockClass} image={image} loading="eager" />
        <ScienceAbout>
          <h2 className={currentLocaleClass}>{title}</h2>
          <p>{text1}</p>
          <p>{text2}</p>
          <p>{text3}</p>
        </ScienceAbout>
      </ScienceCont>
    </ContBig>
  );
}
export default Science;

Science.propTypes = {
  image: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.node,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
};

Science.defaultProps = {
  image: {},
  title: null,
  text1: null,
  text2: null,
  text3: null,
};
