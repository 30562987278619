import styled from 'styled-components';
import { mediaInv, colors } from '../../../ui/common';

export const BornFromCont = styled.div`
  box-sizing: border-box;
  color: ${colors.color28};
  margin-bottom: 100px;
  text-align: center;
  & h2 {
    font-family: 'Craftwork Grotesk';
    font-weight: 400;
    font-size: 36px;
    line-height: 39px;
    margin: 0;
    ${mediaInv.tablet`
    font-size: 28px;
    line-height: 31px;
`}
  }
  & .lang-special:not(.en, .ru, .en-UK, .en-AU) {
    font-family: 'Neue Machina';
    font-weight: 300;
  }
  & p {
    font-family: 'HelveticaNeueCyr';
    font-size: 16px;
    line-height: 20px;
    margin: 16px auto 40px auto;
    max-width: 616px;
  }
`;

export const BornFromPics = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  ${mediaInv.tablet`
  flex-wrap: wrap;
`}
  & .gatsby-image-wrapper {
    width: 30.7%;
    height: 450px;
    border-radius: 24px;
    ${mediaInv.laptop`
     width: 31.6%;
     & img {
      border-radius: 24px;
     }
  `}
    ${mediaInv.tablet`
  width: 100%;
  height: 230px;
  margin-bottom: 15px;
  & img {
    border-radius: 24px;
   }
`}
@media (max-width: 395px) {
      height: 180px;
    }
  }
`;
